import React from 'react';
import { isMobile } from 'react-device-detect';
import convertUrl from '../utils/convertUrl';

const FeedImage = ({
  imageRefs,
  file,
  id,
  handleAvatarDialogOpen,
  token,
  marginTop,
  width,
}) => {
  return (
    <img
      ref={(el) => imageRefs.current.push(el)}
      src={convertUrl(file)}
      alt={id}
      loading="lazy"
      style={{
        cursor: 'pointer',
        width: width ? width : isMobile ? '100%' : '600px',
        marginTop: marginTop,
      }}
      onClick={(e) => handleAvatarDialogOpen(file)}
    />
  );
};

export default FeedImage;
