import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUserPhoneConfirm } from '../actions/userActions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../components/Message';
import { USER_PHONE_VERIFY_RESET } from '../constants/userConstants';

const PhoneVerifyScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [code, setCode] = useState('');

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails, error: errorDetails } = userDetails;

  const userVerifyPhoneConfirm = useSelector(
    (state) => state.userVerifyPhoneConfirm
  );
  const {
    loading: loadingVerifyPhoneConfirm,
    error: errorVerifyPhoneConfirm,
    success,
  } = userVerifyPhoneConfirm;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    }
  }, [success]);

  useEffect(() => {
    dispatch({ type: USER_PHONE_VERIFY_RESET });
    if (!searchParams.get('token')) {
      navigate('/profile');
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyUserPhoneConfirm(code, searchParams.get('token')));
  };
  return (
    <Container style={{ minHeight: '50vh' }}>
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Typography variant="h2">Verify Phone</Typography>
            <Typography variant="p">
              An SMS with a verification code was just sent to your phone
              number: {user.phone}. Please check and submit the code here to
              verify your phone number.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              type={'text'}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              label="Code"
            />{' '}
          </Grid>

          <Grid item xs={12}>
            <Link to="/profile">
              <Button
                variant="outlined"
                disableElevation
                style={{ marginRight: '5px' }}
              >
                Back
              </Button>{' '}
            </Link>
            <Button variant="contained" disableElevation type="submit">
              Submit{' '}
              {loadingVerifyPhoneConfirm && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default PhoneVerifyScreen;
