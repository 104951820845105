import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionList } from '../actions/transactionActions';
import Meta from '../components/Meta';
import { createFeedback, getFeedbackList } from '../actions/feedbackActions';
import Feedback from '../components/Feedback';
import Message from '../components/Message';

const FeedbackScreen = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [content, setContent] = useState('');

  const transactionList = useSelector((state) => state.transactionList);
  const { loading, transactions } = transactionList;

  const feedbackList = useSelector((state) => state.feedbackList);
  const { loading: loadingFeedback, feedbacks } = feedbackList;

  const feedbackCreate = useSelector((state) => state.feedbackCreate);
  const { loading: loadingCreate, feedback } = feedbackCreate;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (!transactions) {
      dispatch(getTransactionList(page, pageSize));
    }

    dispatch(getFeedbackList(page, pageSize));
  }, [page, pageSize]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (content.length > 0) {
      dispatch(createFeedback(content, handleClose, setContent));
    } else window.alert('Feedback cannot be empty!');
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container sx={{ minHeight: '80vh' }}>
      <Meta
        title={'Feedbacks'}
        description={'List of your feedbacks'}
        keywords={
          'feedback, club dynasty feedback, club dynasty members area, vip members'
        }
      />
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Feedbacks
          </Typography>

          <Card variant="outlined">
            <CardContent>
              <img
                src="https://clubdynasty.ca/wp-content/uploads/2023/06/image_processing20200312-27844-gv9q4j-e1685981018964.jpg"
                alt="Feedback"
                width={'300px'}
              />

              <Typography variant="h6" component="div" sx={{ mb: 1.5 }}>
                "YOU" are the reason we are in business !!!
              </Typography>

              <Typography variant="body2">
                How are we doing? Here at Club Dynasty, we are always striving
                to improve the overall customer experience.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="large" onClick={handleClickOpen}>
                Give Us Some Feedback
              </Button>
            </CardActions>
          </Card>

          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth
              disableScrollLock
            >
              <DialogTitle>Feedback</DialogTitle>
              <DialogContent>
                <TextField
                  label="Add feedback"
                  multiline
                  rows={3}
                  fullWidth
                  inputProps={{ style: { fontSize: 14 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                  size="small"
                  style={{ marginBottom: '10px', marginTop: '10px' }}
                  value={content}
                  onChange={handleChange}
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  variant="outlined"
                  disableElevation
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={(e) => onSubmit(e)}
                >
                  Add{' '}
                  {loadingCreate && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>

        {loading ? (
          <Grid item className="transaction-container" xs={12}>
            <Skeleton height={50} width="100%" />

            <Skeleton height={50} width="75%" />
          </Grid>
        ) : (
          feedbacks?.results?.length > 0 && (
            <>
              {feedbacks?.results?.map((feedback) => (
                <Feedback feedback={feedback} key={feedback.id} />
              ))}
              {feedbacks.total > 1 && (
                <div style={{ marginTop: '16px' }}>
                  <Pagination
                    count={feedbacks.total}
                    page={page}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </>
          )
        )}
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default FeedbackScreen;
