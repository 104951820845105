import axios from 'axios';
import { useQuery } from 'react-query';
import shuffle from '../utils/shuffle';
import { add, format } from 'date-fns';
import { API_URI } from '../constants/appConstants';
import { useEffect } from 'react';
import techniqueChecker from '../utils/techniqueChecker';

const useMasseuses = (values) => {
  const cancelSource = axios.CancelToken.source();

  useEffect(() => {
    // This function will be called when the component unmounts
    return () => {
      // Cancel the Axios request when the component unmounts
      cancelSource.cancel('Request canceled due to component unmount');
    };
  }, []);

  const fetchMasseuses = async (values) => {
    const {
      page,
      page_size,
      bust_enhanced,
      services,
      newMasseuse,
      language,
      tattoos,
      available_on,
      hair_color,
      background,
      technique,
      active,
      profile_visible,
    } = values;

    const params = {
      active: active ?? '',
      page: page ?? 1,
      page_size: page_size ?? 1000,
      bust_enhanced: bust_enhanced ?? '',
      services: services ?? '',
      new: newMasseuse ?? '',
      language: language ?? '',
      tattoos: tattoos ?? '',
      hair_color: hair_color ?? '',
      background: background ?? '',
      profile_visible: profile_visible ?? '',
    };

    try {
      const { data } = await axios.get(`${API_URI}/masseuses/`, {
        params,
        cancelToken: cancelSource.token,
      });

      let availableMasseuses = [];

      if (available_on === 'today') {
        availableMasseuses = await axios.get(
          `${API_URI}/masseuses/available/`,
          {
            cancelToken: cancelSource.token,
          }
        );
      }

      const schedule = await axios.get(`${API_URI}/schedule/`, {
        cancelToken: cancelSource.token,
      });

      const sessionRatings = await axios.get(`${API_URI}/members/ratings/`);

      let tomorrowAvailableMasseuses = [];
      if (available_on === 'tomorrow') {
        const tomorrow = add(new Date(), { days: 1 });
        const tomorrowDate = format(tomorrow, 'yyyy-MM-dd');

        tomorrowAvailableMasseuses = schedule.data.filter((item) =>
          item.start.startsWith(tomorrowDate)
        );
      }

      if (techniqueChecker(technique)) {
        let nonVacationSensualMasseuses = data.results.filter(
          (masseuse) =>
            techniqueChecker(masseuse.technique) && masseuse.vacation === false
        );
        let vacationSensualMasseuses = data.results.filter(
          (masseuse) =>
            techniqueChecker(masseuse.technique) && masseuse.vacation === true
        );

        data.results = [
          ...shuffle(nonVacationSensualMasseuses),
          ...vacationSensualMasseuses,
        ];
      } else if (technique === 'Shiatsu') {
        let nonVacationShiatsuMasseuses = data.results.filter(
          (masseuse) =>
            masseuse.technique === 'Shiatsu' && masseuse.vacation === false
        );
        let vacationShiatsuMasseuses = data.results.filter(
          (masseuse) =>
            masseuse.technique === 'Shiatsu' && masseuse.vacation === true
        );
        data.results = [
          ...shuffle(nonVacationShiatsuMasseuses),
          ...vacationShiatsuMasseuses,
        ];
      } else {
        let sensualMasseuses = data.results.filter(
          (masseuse) =>
            techniqueChecker(masseuse.technique) && masseuse.vacation === false
        );
        let shiatsuMasseuses = data.results.filter(
          (masseuse) =>
            masseuse.technique === 'Shiatsu' && masseuse.vacation === false
        );
        let vacationMasseuses = data.results.filter(
          (masseuse) => masseuse.vacation === true
        );

        data.results = [
          ...shuffle(sensualMasseuses),
          ...shuffle(shiatsuMasseuses),
          ...vacationMasseuses,
        ];
      }

      let todayAvlMasseuses = [];
      let tomorrowAvlMasseuses = [];

      data.results.forEach((masseuse) => {
        let sessionRating = sessionRatings.data.find(
          (obj) => obj.id === masseuse.id
        );

        if (sessionRating) {
          masseuse.sessionRating = sessionRating;
        }

        if (available_on === 'today') {
          availableMasseuses.data.forEach((m) => {
            if (masseuse.id === m.id) {
              todayAvlMasseuses.push(masseuse);
            }
          });
        }

        if (available_on === 'tomorrow') {
          tomorrowAvailableMasseuses.forEach((m) => {
            if (
              m.name === masseuse.name &&
              m.background === masseuse.background
            ) {
              tomorrowAvlMasseuses.push(masseuse);
            }
          });
        }

        masseuse.schedule = schedule.data.filter(
          (schedule) =>
            schedule.name === masseuse.name &&
            schedule.background === masseuse.background
        );

        // Main Images
        const index = masseuse.images.indexOf(masseuse.image);
        if (index !== -1) masseuse.images.splice(index, 1);

        let newThumbnails = [];

        for (let i = 0; i < masseuse.images.length; i++) {
          newThumbnails.push(
            'https://clubdynasty.ca/media/users/thumbnails' +
              masseuse.images[i].split('images')[1]
          );
        }

        masseuse.newThumbnails = newThumbnails;

        // Thumbnails
        let indexThumb = -1;
        for (let i = 0; i < masseuse.thumbnails.length; i++) {
          if (
            (masseuse.thumbnails[0].split('thumbnails')[0] =
              masseuse.image.split('images')[0])
          ) {
            indexThumb = i;
            break;
          }
        }
        if (indexThumb !== -1) masseuse.thumbnails.splice(indexThumb, 1);
      });

      if (available_on === 'today') {
        data.results = todayAvlMasseuses;
      } else if (available_on === 'tomorrow') {
        data.results = tomorrowAvlMasseuses;
      }

      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  };

  return useQuery(['masseuses', values], () => fetchMasseuses(values), {
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });
};

export default useMasseuses;
