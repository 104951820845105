import './VideoCarousel.css';
import React, { Suspense, lazy, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { isVideo } from '../utils/isVideo';

const VideoJsPlayer = lazy(() => import('../components/VideoJsPlayer'));
const FeedImage = lazy(() => import('./FeedImage'));

const ImageAndVideoCarousel = ({
  media,
  handleAvatarDialogOpen,
  imageRefs,
  token,
}) => {


  return (
    <Carousel style={{ marginBottom: '-15px', marginTop: '15px' }}>
      {media.map((m, i) => {
        return (
          <Carousel.Item key={i} interval={null}>
            {isVideo(m.hls ? m.hls : m.file) ? (
              <Suspense
                fallback={
                  <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                  />
                }
              >
                <VideoJsPlayer media={m} />
              </Suspense>
            ) : (
              <Suspense
                fallback={
                  <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                  />
                }
              >
                <FeedImage
                  imageRefs={imageRefs}
                  file={m.file}
                  id={m.id}
                  handleAvatarDialogOpen={handleAvatarDialogOpen}
                  token={token}
                />
              </Suspense>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default ImageAndVideoCarousel;
