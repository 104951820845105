import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import * as Sentry from '@sentry/react';
import formatError from '../utils/errors';
import {
  FEED_BLOCK_CREATE_FAIL,
  FEED_BLOCK_CREATE_REQUEST,
  FEED_BLOCK_CREATE_SUCCESS,
  FEED_BLOCK_DELETE_FAIL,
  FEED_BLOCK_DELETE_REQUEST,
  FEED_BLOCK_DELETE_SUCCESS,
  FEED_BLOCK_LIST_FAIL,
  FEED_BLOCK_LIST_REMOVE_ITEM,
  FEED_BLOCK_LIST_REQUEST,
  FEED_BLOCK_LIST_SUCCESS,
  FEED_FAVORITE_CREATE_FAIL,
  FEED_FAVORITE_CREATE_REQUEST,
  FEED_FAVORITE_CREATE_SUCCESS,
  FEED_FAVORITE_DELETE_FAIL,
  FEED_FAVORITE_DELETE_REQUEST,
  FEED_FAVORITE_DELETE_SUCCESS,
  FEED_FAVORITE_DETAILS_FAIL,
  FEED_FAVORITE_DETAILS_REQUEST,
  FEED_FAVORITE_DETAILS_SUCCESS,
  FEED_FAVORITE_LIST_FAIL,
  FEED_FAVORITE_LIST_REMOVE_ITEM,
  FEED_FAVORITE_LIST_REQUEST,
  FEED_FAVORITE_LIST_SUCCESS,
} from '../constants/feedConstants';
import { setAlert } from './alertActions';

export const incrementFeedMediaVisit = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.post(`${API_URI}/feed/${id}/visits/`, config);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const getFeedBlockList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEED_BLOCK_LIST_REQUEST,
    });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: userInfo.token,
      },
    };

    let { data } = await axios.get(
      `${API_URI}/feed_blocks/?page=${1}&page_size=${100}`,
      config
    );

    dispatch({
      type: FEED_BLOCK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setAlert(error.response.data.detail, 'error'));

    dispatch({
      type: FEED_BLOCK_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.response.data,
    });
  }
};

export const createFeedBlock =
  (masseuse, handleBlockPopupClose) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FEED_BLOCK_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
        // feedbackList: { feedbacks },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/feed_blocks/`,
        {
          masseuse: masseuse.id,
        },
        config
      );

      dispatch({
        type: FEED_BLOCK_CREATE_SUCCESS,
        payload: data,
      });

      dispatch(
        setAlert(
          `${masseuse.name} has been blocked, you will no longer see posts from her in the feed. To unblock, go to your profile.`,
          'success'
        )
      );

      handleBlockPopupClose();
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: FEED_BLOCK_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteFeedBlock =
  (masseuse, block_id, handleUnblockPopupClose) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FEED_BLOCK_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.delete(
        `${API_URI}/feed_blocks/${block_id}/`,
        config
      );

      dispatch({
        type: FEED_BLOCK_LIST_REMOVE_ITEM,
        payload: block_id,
      });

      dispatch({
        type: FEED_BLOCK_DELETE_SUCCESS,
        payload: data,
      });

      dispatch(
        setAlert(
          `${masseuse.name} has been unhidden, you will now see posts from her in the feed.`,
          'success'
        )
      );

      handleUnblockPopupClose();
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: FEED_BLOCK_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getFeedFavoriteList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEED_FAVORITE_LIST_REQUEST,
    });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: userInfo.token,
      },
    };

    let { data } = await axios.get(
      `${API_URI}/feed_favorites/?page=${1}&page_size=${100}`,
      config
    );

    dispatch({
      type: FEED_FAVORITE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setAlert(error.response.data.detail, 'error'));

    dispatch({
      type: FEED_FAVORITE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.response.data,
    });
  }
};

export const getFeedFavoriteDetails =
  (masseuse) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FEED_FAVORITE_DETAILS_REQUEST,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.get(
        `${API_URI}/feed_favorites/${masseuse.id}/`,
        config
      );

      dispatch({
        type: FEED_FAVORITE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: FEED_FAVORITE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };

export const createFeedFavorite =
  (masseuse, setMasseuseFavorite) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FEED_FAVORITE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/feed_favorites/`,
        {
          masseuse: masseuse.id,
        },
        config
      );

      dispatch({
        type: FEED_FAVORITE_CREATE_SUCCESS,
        payload: data,
      });

      if (setMasseuseFavorite) {
        setMasseuseFavorite(data);
      }
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: FEED_FAVORITE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteFeedFavorite =
  (favoriteId, setMasseuseFavorite, masseuse, unfavoritePopupOpen) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FEED_FAVORITE_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.delete(
        `${API_URI}/feed_favorites/${favoriteId}/`,
        config
      );

      if (unfavoritePopupOpen) {
        dispatch({
          type: FEED_FAVORITE_LIST_REMOVE_ITEM,
          payload: favoriteId,
        });
      }

      dispatch({
        type: FEED_FAVORITE_DELETE_SUCCESS,
        payload: data,
      });
      setMasseuseFavorite(unfavoritePopupOpen ? false : null);
      dispatch(
        setAlert(
          `${masseuse?.name} has been unfavorited, you will no longer receive notifications about her.`,
          'success'
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: FEED_FAVORITE_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
