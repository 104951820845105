import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function useOwnRatings(token) {
  const fetchOwnRatings = async () => {
    const endpoint = `${API_URI}/members/ratings/`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch masseuse details');
    }
  };

  const queryKey = ['ownRatings'];

  const { data, error, isLoading } = useQuery(queryKey, fetchOwnRatings, {
    cacheTime: 0,
    staleTime: 0,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    ownRatings: data,
    isLoading,
    error,
  };
}

export default useOwnRatings;
