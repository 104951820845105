import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useSelector } from 'react-redux';
import useImageSrc from '../hooks/useImageSrc';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Popup({ popup, open, setOpen }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const imageSrc = useImageSrc(userInfo?.token ?? '', popup?.image ?? '');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth disableScrollLock>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {popup?.title}
        </BootstrapDialogTitle>

        <Card variant="outlined">
          <img src={imageSrc} alt={`${popup?.title}`} width={'100%'} />
          <CardContent>
            <Typography variant="body2" color="text.primary">
              {popup?.description}
            </Typography>
          </CardContent>
        </Card>

        <DialogActions>
          <a href="/profile">
            <Button variant="contained" disableElevation size="small">
              Confirm Info
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </div>
  );
}
