import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useTransactions from '../../hooks/useTransactions';
import calculateExpiryDate from '../../utils/calculateExpiryDate';

const PointsEarningHistoryTable = ({ transactions, totalPoints }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { data, isLoading, error, fetchNextPage } = useTransactions(
    userInfo.token
  );

  return (
    <TableContainer component={Paper} variant="outlined">
      {data?.pages.length > 0 ? (
        <InfiniteScroll
          dataLength={data?.pages?.length ?? 0}
          next={fetchNextPage}
          hasMore={
            !!data?.pages &&
            data.pages.slice(-1)[0].total > data.pages.slice(-1)[0].current
          }
          loader={
            <>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '0.8rem',
                  padding: '0.5rem',
                }}
              >
                Keep scrolling down to load more
              </p>
              <LinearProgress />
            </>
          }
          endMessage={
            <p
              style={{
                textAlign: 'center',
                fontSize: '0.8rem',
                padding: '0.5rem',
              }}
            >
              You have reached the end of the list
            </p>
          }
          scrollableTarget="scrollableDiv"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Session</TableCell>
                <TableCell>Masseuse</TableCell>
                <TableCell>Earned on</TableCell>
                <TableCell>Expires on</TableCell>
                <TableCell align="right">Earned</TableCell>
                <TableCell align="right">Redeemed</TableCell>
                <TableCell align="right">Balance</TableCell>
                <TableCell padding="none"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.pages.map((page, index) => (
                <React.Fragment key={index}>
                  {page.results.map((transaction, index) => (
                    <TableRow key={transaction.id + '-' + index}>
                      <TableCell>{transaction.session_type}</TableCell>
                      <TableCell>
                        {transaction.masseuses.map((masseuse, index) => (
                          <span key={masseuse.id + '-' + index}>
                            <Link to={`/masseuse/${masseuse?.id}`}>
                              {masseuse.name}
                            </Link>
                            {index < transaction.masseuses.length - 1
                              ? ', '
                              : ''}
                          </span>
                        ))}
                      </TableCell>
                      <TableCell>
                        {format(new Date(transaction.start_time), 'PP')}
                      </TableCell>
                      <TableCell>
                        {format(
                          new Date(calculateExpiryDate(transaction.start_time)),
                          'PP'
                        )}
                      </TableCell>
                      <TableCell align="right">{transaction.points}</TableCell>
                      <TableCell align="right">
                        {transaction.redemption}
                      </TableCell>
                      <TableCell align="right">
                        {/* {totalPoints - transaction.redemption} */}
                        {transaction.balance_points}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      ) : (
        <p
          style={{
            textAlign: 'center',
            fontSize: '0.8rem',
            padding: '0.5rem',
          }}
        >
          No data available
        </p>
      )}
    </TableContainer>
  );
};

export default PointsEarningHistoryTable;
